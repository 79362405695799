@import "~react-datepicker/dist/react-datepicker.css";
@import "vendor/toastr";

body.authentication-bg-pattern {
    background: url(../images/bg.jpg) center no-repeat;
    background-size: cover;
}

.logo-box {
    img {
        width: 200px;
    }
}

.table-changes {
    .key {
        width: 150px;
    }

    .limited {
        display: inline-block;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.compact {
    width: 1%;
    white-space: nowrap;
}

.buttons {
    width: 1%;
    white-space: nowrap;

    .btn:not(:last-child) {
        margin-right: 3px;
    }
}

.preloader {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.header-buttons {
    margin-bottom: $spacer;
}

.mb-n3 {
    margin-bottom: -1.5rem !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.invalid-feedback {
    display: block !important;
}

.table {
    .table {
        margin-bottom: 0;
    }
}

td.compact, th.compact {
    width: 1%;
    white-space: nowrap;
}

.navbar-custom .app-search {
    overflow: auto;
}

.chart-wrapper {
    background-color: #fff;
}
